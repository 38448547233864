import React from "react"
import { PrimeIcons } from "primereact/api"
import { Panel } from "primereact/panel"
import { useHistory, useParams } from "react-router-dom"

import { TawreedAction, TawreedActionName } from "../../../../../common/actions"
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea, TawreedMenuDropdown, TawreedNavLink } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { Routes } from "../../../../../router"
import { PartnerType } from "../../../../partners/data"
import { PartnerBusinessTypeDropdown } from "../../../../partners/presentaion/components/partner-business-type-dropdown"
import { StatesDropdown } from "../../../../states"
import { TopUpPartner, TopUpPartnerService } from "../../../domain"
import { ZonesDropdown } from "../../../../zones"

export const TopUpPartnerForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service: TopUpPartnerService = React.useMemo(() => new TopUpPartnerService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<TopUpPartner>();
    const [actions, setActions] = React.useState<TawreedAction[]>([]);

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    console.log(res);

                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onCreateOrSave = (data: TopUpPartner, cb: TawreedFormSubmitCallback<TopUpPartner>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate(data)
            .then((res) => {
                if (mode === 'Create') {
                    history.replace(Routes.TopUpPartnersDetails.replace(':id', res.topUpPartnerId.toString()));
                }
                setLoading(false);
                setData(res);
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<TopUpPartner> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<TopUpPartner>(TawreedActionName.TopUpPartnersSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };

    React.useEffect(
        () => {
            const onDelete = () => {
                setLoading(true);
                return service.delete(data!.topUpPartnerId!).then(res => {
                    setLoading(false);
                    history.replace(Routes.TopUpPartnersSearch);
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }

            const onCleanup = () => {
                setLoading(true);
                return service.cleanup(data!.topUpPartnerId!)
                    .then(res => {
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        throw err;
                    });
            }

            const next = [
            ];
            if (data && data.topUpPartnerId) {
                next.push(new TawreedAction(TawreedActionName.TopUpPartnersDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', true));
                next.push(new TawreedAction(TawreedActionName.TopUpPartnersCleanup, 'statefull', 'lbl_cleanup', PrimeIcons.REFRESH, onCleanup));
            }
            setActions(next);
        },
        [data, service, history]);


    return (
        <TawreedForm title="lbl_topup_partners"
            dataKey="topUpPartnerId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            actions={actions} useBack=".." useReset={true} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <div className="col-12">
                        <div className="grid">
                            <TawreedFormField name="logoContent" className="field col-12 md:col-3" title="lbl_partners_logo">
                                <TawreedInputFile render="form" name="logoContent" accept="image" />
                            </TawreedFormField>
                        </div>
                    </div>

                    {
                        id &&
                        <React.Fragment>
                            <TawreedFormField name="partnerId" className="field col-12 md:col-6" title="lbl_partners_partnerid">
                                <TawreedInputText name="partnerId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                            <TawreedFormField name="balanceId" className="field col-12 md:col-6" title="lbl_partners_balanceid">
                                <TawreedInputText name="balanceId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                        </React.Fragment>
                    }

                    <TawreedFormField name="businessType" className="field col-12 md:col-6" title="lbl_partners_business_type">
                        <PartnerBusinessTypeDropdown disabled={id ? true : false} partnerType={PartnerType.TopUp} name="businessType" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                        <TawreedInputSwitch name="active" render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="partnerName" className="field col-12 md:col-6" title="lbl_partners_partner_name">
                        <TawreedInputText name="partnerName" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                </div>
            </Panel>

            <Panel header={tawreedTranslationService.translate('lbl_partners_address_information')} className="col-12">
                <div className="grid">
                    {/* <TawreedFormField name="address.zoneId" className="field col-12 md:col-12" title="lbl_partners_address_zone">
                        <ZonesDropdown name="address.zoneId" rules={{ required: 'msg_field_is_required' }} render="form" state={{ render: 'form', name: 'address.stateId' }} />
                    </TawreedFormField> */}
                    <TawreedFormField name="address.stateId" className="field col-12 md:col-12" title="lbl_partners_address_state_id">
                        <StatesDropdown name="address.stateId" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="address.street" className="field col-12" title="lbl_partners_address_street">
                        <TawreedInputText name="address.street" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    {/* <TawreedFormField name="address.latitude" className="field col-12 md:col-6" title="lbl_partners_address_latitude">
                        <TawreedInputNumber name="address.latitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                    </TawreedFormField>
                    <TawreedFormField name="address.longitude" className="field col-12 md:col-6" title="lbl_partners_address_longitude">
                        <TawreedInputNumber name="address.longitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                    </TawreedFormField>

                    {
                        id &&
                        <TawreedFormField name="address.location" className="field col-12" title="">
                            <TawreedNavLink render="form" name="address.location">
                                {tawreedTranslationService.translate('lbl_partners_address_location')}
                            </TawreedNavLink>
                        </TawreedFormField>
                    } */}
                </div>
            </Panel>

            {
                id &&
                <Panel header={tawreedTranslationService.translate('lbl_partners_contact_information')} className="col-12">
                    <div className="grid">
                        <TawreedFormField name="contact.contactName" className="field col-12 md:col-6" title="lbl_partners_contact_name">
                            <TawreedInputText name="contact.contactName" render="form" disabled readOnly />
                        </TawreedFormField>
                        <TawreedFormField name="contact.mobileNo" className="field col-12 md:col-6" title="lbl_partners_contact_mobile">
                            <TawreedInputText name="contact.mobileNo" render="form" disabled readOnly />
                        </TawreedFormField>
                        <TawreedFormField name="contact.email" className="field col-12 md:col-12" title="lbl_partners_contact_email">
                            <TawreedInputText name="contact.email" render="form" disabled readOnly />
                        </TawreedFormField>
                    </div>
                </Panel>
            }

            <Panel header={tawreedTranslationService.translate('lbl_topup_partners_topup_information')} className="col-12">
                <div className="grid">
                    {
                        !id &&
                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField className="field col-6 md:col-6" name="topUpCode" title="lbl_topup_partners_topupcode">
                                    <TawreedInputText render="form" name="topUpCode" rules={{ required: 'msg_field_is_required', maxLength: { value: 5, message: 'msg_field_is_too_long' } }} />
                                </TawreedFormField>
                            </div>
                        </div>
                    }

                    <TawreedFormField className="field col-6 md:col-6" name="topUpFeesType" title="lbl_topup_partners_topupfeestype">
                        <TawreedMenuDropdown render="form" name="topUpFeesType" menu="discountTypesBasics" optionValue="id" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="topUpFeesValue" title="lbl_topup_partners_topupfeesvalue">
                        <TawreedInputNumber render="form" name="topUpFeesValue" mode="decimal" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="platformPercentage" title="lbl_topup_partners_platformpercentage">
                        <TawreedInputNumber render="form" name="platformPercentage" rules={{ required: 'msg_field_is_required', max: 100, min: 0 }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="partnerPercentage" title="lbl_topup_partners_partnerpercentage">
                        <TawreedInputNumber render="form" name="partnerPercentage" rules={{ required: 'msg_field_is_required', max: 100, min: 0 }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="topUpCashBackType" title="lbl_topup_partners_topupcashbacktype">
                        <TawreedMenuDropdown render="form" name="topUpCashBackType" menu="discountTypesBasics" optionValue="id" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="topUpCashBackValue" title="lbl_topup_partners_topupcashbackvalue">
                        <TawreedInputNumber render="form" name="topUpCashBackValue" mode="decimal" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="topUpCostType" title="lbl_topup_partners_topupcosttype">
                        <TawreedMenuDropdown render="form" name="topUpCostType" menu="discountTypesBasics" optionValue="id" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="topUpCostValue" title="lbl_topup_partners_topupcostvalue">
                        <TawreedInputNumber render="form" name="topUpCostValue" mode="decimal" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="minimumTopUp" title="lbl_topup_partners_minimumtopup">
                        <TawreedInputNumber render="form" name="minimumTopUp" rules={{ required: 'msg_field_is_required' }} min={1} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="maximumTopUp" title="lbl_topup_partners_maximumtopup">
                        <TawreedInputNumber render="form" name="maximumTopUp" rules={{ required: 'msg_field_is_required' }} min={1} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-12" name="terms" title="lbl_topup_partners_terms">
                        <TawreedInputTextarea render="form" name="terms" />
                    </TawreedFormField>

                    <TawreedFormField className="field col-12" name="payLaterTerms" title="lbl_topup_partners_paylaterterms">
                        <TawreedInputTextarea render="form" name="payLaterTerms" />
                    </TawreedFormField>

                    <TawreedFormField className="field-checkbox col-12 md:col-6" name="topUpThroughUs" title="lbl_topup_partners_topupthroughus" flip>
                        <TawreedInputSwitch render="form" name="topUpThroughUs" />
                    </TawreedFormField>

                    <TawreedFormField className="field col-12 md:col-6" name="instructionUrl" title="lbl_topup_partners_instructionurl">
                        <TawreedInputText render="form" name="instructionUrl" />
                    </TawreedFormField>

                    <TawreedFormField className="field col-12 md:col-6" name="channel" title="lbl_topup_partners_channel">
                        <TawreedInputText render="form" name="channel" />
                    </TawreedFormField>

                    <TawreedFormField className="field col-12 md:col-6" name="walletId" title="lbl_topup_partners_walletid">
                        <TawreedInputText render="form" name="walletId" />
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    )
}
