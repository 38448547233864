import React, { FC } from "react";
import { PartnerType } from "../../data";
import { PartnersList } from "../components";
import { PartnerForm } from "../components/partner-form";
import { ConnectionList } from "../components";

/**
 * Tawreed Stores Page
 * @returns 
 */
export const StoresPage: FC = () => (<PartnersList type={PartnerType.Store} />);


/**
 * Tawreed Store Form Page
 * @returns 
 */
export const StoreFormPage: FC = () => (<PartnerForm type={PartnerType.Store} />);

/**
 * Tawreed Stores Page
 * @returns 
 */
export const ConnectionsPage: FC = () => (<ConnectionList />);
