import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React, { useState, useEffect, useMemo } from "react";
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { priceSchemeDto, PriceSchemeService } from "../../domain";
import { DiscountTypesDropDown } from "./discount-types";
import { PrimeIcons } from "primereact/api";
import { tawreedTranslationService } from "../../../../../common/translations";
import { BonusTable } from "./bonus-table";
import { IBonusType } from "../../../../constants";
import { GlobalContext } from "../../../../../context";
import { PriceUtils } from "../../../../../helpers/price";
import { toastService } from "../../../../notifications/domain";

export type PriceListFormProps = {
    name: string;
    storeProductId: number,
    priceListId: number,
    price?: number,
    loading?: boolean;
    ref1?: any;
    onSavePriceList?: (data: priceSchemeDto) => void;
}

export const PriceListForm: React.FC<PriceListFormProps> = (_props) => {

    const [data, setData] = useState<priceSchemeDto>({
        priceListId: _props.priceListId,
        storeProductId: _props.storeProductId,
        discountValue: 0,
        sellingPrice:0
    });
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(false);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [disabeld, setDisabled] = useState<boolean>(true);

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    const service: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);
    const priceUtils: PriceUtils =  new PriceUtils();

    useEffect(()=>{
        let time = setTimeout(() => {
            setLoader(true);
            priceUtils.getSellingPrice(data.price ?? 0, data.discountType ?? 'fixed', data.discountValue ?? 0)
            .then((res)=>{
            setLoader(false);
            if(res>0)
                {
                    setData({...data, sellingPrice: res});
                    setSellingPrice(res);
                }
                else{
                    setSellingPrice(0);
                    setData({...data, sellingPrice: 0});
                    toastService.show({ detail: tawreedTranslationService.translate('lbl_selling_price_error'), severity: 'error' });
                    
                }
        }).catch((res)=>{
            setLoader(false);
        });
        }, 500)

        return () => clearTimeout(time);
    },[data.discountType, data.discountValue, data.price]);
    

    useEffect(() => {
        if (_props.loading === false) {
            setLoading(true);
            service.getDetails(_props.priceListId, _props.storeProductId)
                .then(res => {
                    if (res.priceSchemeId) {

                        setData({
                            ...res, price: res.price ?? _props.price,
                            priceListId: _props.priceListId, storeProductId: _props.storeProductId
                        });
                        setDisabled(false);
                    }
                    else {
                        console.log("else data", data);

                        setData({
                            ...res, price: res.price ?? _props.price, discountValue: 0, bonuses: [],
                            priceListId: _props.priceListId, storeProductId: _props.storeProductId
                        });
                    }
                    setLoading(false);

                    console.log("pricelist", res);
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                });
        }
        // eslint-disable-next-line
    }, [_props.priceListId, _props.loading])


    useEffect(() => {
        setData(previous => { return { ...previous, price: _props.price } });
    }, [_props.price])



    const onSave = async () => {
        if (!disabeld) {
            try {
                setLoading(true);
                //setData({...data,sellingPrice:sellingPrice});
                await _props.onSavePriceList?.(data)

                // let response=await service.createOrUpdate(data);
                // setData({...response})
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
    }
    const deleteBonusById = async (uniqueId: string, id?: number | undefined) => {
        try {
            setLoading(true);
            if (id) {
                await service.deleteBonusById(id);
                let bonuses = data.bonuses?.filter(el => el.bonusId !== id);
                setData({ ...data, bonuses: bonuses });
            }
            else {
                let bonuses = data.bonuses?.filter((el) => el.uniqueId !== uniqueId);
                setData({ ...data, bonuses: bonuses });
            }

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    const addBonus = (bonus: [{ bonus: number, quantity: number, bonusType: string, discountType?: string, bounsOperation?: string; classificationId?:number }]) => {
        let bonuses = data.bonuses ? [...data.bonuses] : [];
        bonus.forEach((e)=>{
            if (data.priceSchemeId)
                bonuses?.push({ priceSchemeId: data.priceSchemeId, ...e, uniqueId: new Date().getTime().toString() })
            else
            bonuses?.push({ ...e, uniqueId: new Date().getTime().toString() })
            
        });
        setData({ ...data, bonuses: bonuses })
    }
    const onDelete = () => {

        if (data.priceSchemeId) {
            setLoading(true);
            service.deletePriceScheme(data.priceSchemeId).then(() => {
                setData({
                    price: data.price, discountValue: data.discountValue,
                    bonuses: [], priceListId: _props.priceListId, storeProductId: _props.storeProductId
                })
                setLoading(false)
                setDisabled(true);
            }
            ).catch(err => setLoading(false));

        }
    }
    const onEdit = () => {
        setDisabled(!disabeld);
    }
    return (
        <React.Fragment>
            <div style={{ position: 'relative', }}>
                {loading && <div style={{ position: 'absolute', zIndex: 5, backgroundColor: '#00000045', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <i style={{ fontSize: '2rem' }} className="form-tawreed-loader-icon pi-spin pi pi-spinner"></i>
                </div>}

                <Panel header={_props.name}
                    icons={
                        (user?.roleCode === 'ADMIN' || user?.roleCode ==='SYS_ADMIN' ||
                        user?.roleCode === 'STORE_ADMIN' || user?.roleCode === 'SALES' ||
                        user?.roleCode === 'MANAGER' || user?.roleCode === 'CATMANAGER') &&
                        <React.Fragment>
                            <Button
                                label={tawreedTranslationService.translate("lbl_save")}
                                icon={PrimeIcons.SAVE}
                                loading={loading}
                                style={{ display: 'none' }}
                                ref={_props.ref1}
                                onClick={onSave}
                            />
                            {data.priceSchemeId && <Button
                                label={tawreedTranslationService.translate("lbl_delete")}
                                icon={PrimeIcons.TRASH}
                                className="p-button-danger"
                                loading={loading}
                                onClick={onDelete}
                            />}
                            {!data.priceSchemeId && disabeld && <Button
                                label={tawreedTranslationService.translate("lbl_add_price")}
                                icon={PrimeIcons.PLUS}
                                className="p-button-primary"
                                loading={loading}
                                onClick={onEdit}
                            />}
                            {!data.priceSchemeId && !disabeld && <Button
                                label={tawreedTranslationService.translate("lbl_cancel")}
                                icon={PrimeIcons.PENCIL}
                                className="p-button-danger"
                                loading={loading}
                                onClick={onEdit}
                            />}

                        </React.Fragment>

                    }
                    className="col-12">

                    <div className="grid">


                        <TawreedFormField name="price" title="lbl_price_scheme_price" className="field col-3 lg:col-3 md:col-6">
                            <TawreedInputNumber min={0} disabled={disabeld} mode="decimal" value={data?.price} name="price" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setData({ ...data, price: e.value && e.value>=0 ? e.value : 0 })
                            }} />
                        </TawreedFormField>

                        <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                            <DiscountTypesDropDown disabled={disabeld} value={data?.discountType} name="discountType" render="form" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                setData({ ...data, discountType: e.value })
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="discountValue" title="lbl_price_scheme_discountValue" className="field col-3 lg:col-3 md:col-6">
                            <TawreedInputNumber disabled={disabeld} mode={"decimal"} value={data?.discountValue} name="discountValue" rules={{ required: "msg_field_is_required" }} render="form" onChange={(e) => {
                                setData({ ...data, discountValue: e.value && e.value>=0 ? e.value : 0 })
                            }} />
                        </TawreedFormField>
                        {(data.price && data.price > 0) ?
                            <TawreedFormField name="sellingPrice" title="lbl_sellingPrice" className="field col-3 lg:col-3 md:col-6">
                               <span>
                         {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                           
                         <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={sellingPrice} name="sellingPrice" render="standalone" readOnly />

                            </span>
                            </TawreedFormField> : ''
                        }
                        <BonusTable
                            className="col-12 md:col-12"
                            data={data?.bonuses?.filter(el => el.bonusType === IBonusType.EXTRA_ITEMS) ?? []}
                            title={"lbl_store_products_bonus_title"}
                            deleteBonusById={deleteBonusById}
                            disabeld={disabeld || !data.discountType || !data.price}
                            addBonus={addBonus}
                            loading={loading}
                            bonusType={IBonusType.EXTRA_ITEMS}
                        ></BonusTable>
                        <BonusTable
                            className="col-12 md:col-12"
                            data={data?.bonuses?.filter(el => el.bonusType === IBonusType.DISCOUNT) ?? []}
                            title={"lbl_store_products_discount_bonus_title"}
                            deleteBonusById={deleteBonusById}
                            addBonus={addBonus}
                            disabeld={disabeld || !data.discountType || !data.price}
                            loading={loading}
                            bonusType={IBonusType.DISCOUNT}
                            singlePrice={data.price && data.price > 0 ? sellingPrice : 0}
                        ></BonusTable>

                    </div>
                </Panel>
            </div>
        </React.Fragment>


    )
}
