export enum TawreedActionName {
    // Common
    Logout,
    Back,
    Reset,
    ToggleSelection,
    // DashboardComponent
    DashboardCardsSales,
    DashboardCardsOrdersPlaced,
    DashboardCardsOrdersProcessing,
    DashboardCardsOrdersShipped,
    DashboardCardsOrdersReturnShipped,
    DashboardCardsOrdersReadyForDelivery,
    DashboardCardsPartners,
    DashboardCardBalance,
    DashboardChartOrders,
    DashboardChartOrdersTotals,
    DashboardChartOrdersPerZone,
    DashboardChartOrdersPerState,
    DashboardChartPartners,
    // Users
    UsersCreate,
    UsersDetails,
    UsersSubmit,
    UsersDelete,
    DeleteMyAccount,
    UsersChangePassword,
    // Partners
    StorePartnerCreate,
    CustomerPartnerCreate,
    StorePartnerDetails,
    CustomerPartnerDetails,
    StorePartnerSubmit,
    CustomerPartnerSubmit,
    PartnersDelete,
    PartnersStoresVerify,
    PartnersCustomersVerify,
    PartnersCustomersCreateWallet,
    PartnersFilesUpload,
    PartnersFilesDownload,
    PartnersCustomersUploadLicenseContentId,
    PartnersCustomersUploadTadeRegisterContentId,
    PartnersCustomersUploadLogo,
    PartnerInformation,

    //connections
    PartnerConnectionSetClassification,
    PartnerConnectionRemoveClassification,

    //Payment partner
    PaymentPartnersCreate,
    PaymentPartnersDetails,
    PaymentPartnersDelete,
    PaymentPartnersSubmit,
    PaymentPartnersActivate,
    PaymentPartnersDeactivate,
    //payment transaction
    PaymentTransactionsDetails,
    //payment orders
    PaymentOrderDetails,
    //Delivery partner
    DeliveryPartnersCreate,
    DeliveryPartnersDetails,
    DeliveryPartnersDelete,
    DeliveryPartnersSubmit,
    DeliveryPartnersActivate,
    DeliveryPartnersDeactivate,
    // Products
    ProductsCreate,
    ProductsDetails,
    ProductsSubmit,
    ProductsDelete,
    // Store-Products
    StoreProductsCreate,
    StoreProductsDetails,
    StoreProductsSubmit,
    StoreProductsDelete,
    StoreProductsActivate,
    StoreProductsDeactivate,
    // Store-Product-Stage
    StoreProductsStageImport,
    StoreProductsDownloadTemplate,
    StoreProductsStageApprove,
    StoreProductsStageReject,
    // Carts
    CartsCreate,
    CartsDetails,
    CartsUpdate,
    CartsStoreCartDelete,
    CartsStoreCartItemDelete,
    // Orders
    OrdersDetails,
    OrdersReturnCreate,
    OrdersConfirm,
    OrdersReadyForDelivery,
    OrdersShip,
    OrdersDeliver,
    OrdersCancel,
    OrdersReturn,
    OrdersPrintStore,
    OrdersPrintCustomer,
    OrdersEditQuantity,
    OrdersEditBonus,
    OrderEditDiscountValue,
    OrdersEditRetailPrice,
    OrdersEditDiscountPercent,
    OrdersPartnersDetails,
    OrdersPartnersShipAll,
    OrdersPartnersDeliverAll,
    OrdersPartnersReturnAll,
    OrdersPartnersPrintAll,
    OrdersPartnersPrint,
    // Finance
    FinanceDocumentsCreate,
    //
    ReportsGenerate,

    // zones | stores
    StoreZonesCreate,
    StoreZonesDelete,
    StoreZoneSubmit,
    // zones
    ZonesCreate,
    ZonesDetails,
    ZonesDelete,
    ZonesSubmit,
    //
    ZonesLink,
    ZonesUnlink,
    //
    MarketingCampaign,
    // topup | partners
    TopUpPartnersCreate,
    TopUpPartnersDetails,
    TopUpPartnersSubmit,
    TopUpPartnersDelete,
    TopUpPartnersCleanup,
    // topup | transactions
    TopUpTransactionsDetails,
    //
    WalletTransactionDetails,
    ReportsExport,

    CategoryDetails,
    CategoryNew,
    CategorySubmit,
    CategoryDelete,


    BannerDetails,
    BannerNew,
    BannerSubmit,
    BannerDelete,

    //IMPORTHELPER
    ImpoerHelperDetails,
    ImportUploadTask,

    //sales offers
    SalesOfferDetails,
    SalesOfferCreate,
    SalesOfferSubmit,
    SalesOfferDelete,
    SalesOfferActivate,
    SalesOfferDeactivate
}
